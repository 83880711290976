
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import FileUpload from "@/components/FileUpload.vue";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { Document as ModelDocument, IDocument } from "@/model/Document";

    import * as toastr from "toastr";

    @Component({
        components: { ApiButton, FileUpload }
    })
    export default class UploadTest extends Vue {

        document = new ModelDocument();

        mounted() {
            const documentID = this.$router.currentRoute.params.documentID;
            console.log("document:", this.document);
            this.load(documentID);
        }

        private async load(documentID: string) {
            if (!utils.isGuid(documentID)) {
                documentID = utils.emptyGuidValue;
            }
            //const documentID = "0D9BD8E1-CD44-4F35-C9CF-017294441ABD"; // utils.emptyGuidValue;
            const response: IDocument | null = await apiClient.get(`/Api/Document/Load?id=${documentID}`, event);
            this.document.update(response);
        }

        async save(event: Event) {

            const response = await apiClient.post("/api/document/save", this.document, event);
            if (this.document.isNew) {
                this.document.id = response.newID;
                history.replaceState({}, "", this.$route.path.replace("/0", "/" + response.newID));
            }
            else if (this.document.isDeleted) {
                // saving un-deletes
                this.document.deleted = new Date(utils.emptyDateValue);
                this.document.deletedByUserID = 0;
            }
            toastr.success("saved");
        }

    }
